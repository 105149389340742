/* eslint-disable default-param-last */
import { create } from "zustand";
import { Viewer as CesiumViewer } from "cesium";
import * as Cesium from "cesium";

export type CameraPhase = {
  position: [number, number, number];
  orientation: {
    heading: number;
    pitch: number;
    roll: number;
  };
};

type TilesetWithName = {
  tileset: Cesium.Cesium3DTileset;
  name?: string; // 이름 속성 추가
};

type ViewStatus = {
  viewer: CesiumViewer | null;
  phase: CameraPhase;
  setViewer: (viewer: CesiumViewer) => void;
  setPhase: (data: CameraPhase) => void;
  //
  setTilsets: (
    paths: {
      path: string;
      adjustHeight?: number;
      splitDirection?: Cesium.SplitDirection;
      name?: string;
    }[],
  ) => void;
  tilesetMap: Map<string, TilesetWithName>;
};

const useViewStore = create<ViewStatus>((set, get) => {
  const setTilsets = async (
    paths: {
      path: string;
      adjustHeight?: number;
      splitDirection?: Cesium.SplitDirection;
      name?: string;
    }[],
  ) => {
    const prevTilesets = get().tilesetMap;

    prevTilesets.forEach((ts) => {
      // eslint-disable-next-line no-param-reassign
      ts.tileset.show = false;
      // eslint-disable-next-line no-param-reassign
      ts.tileset.splitDirection = Cesium.SplitDirection.NONE;
    });

    const foo = async (
      path: string,
      adjustHeight = 0,
      splitDirection = Cesium.SplitDirection.NONE,
      name: string | undefined,
    ) => {
      const ts = await Cesium.Cesium3DTileset.fromUrl(path,
        { skipLevelOfDetail: true,
          baseScreenSpaceError: 1024,
          skipScreenSpaceErrorFactor: 16,
          skipLevels: 1,
          immediatelyLoadDesiredLevelOfDetail: false,
          loadSiblings: false,
          cullWithChildrenBounds: true
         });

        //  ts.style = new Cesium.Cesium3DTileStyle({
        //   color: 'color("pink")' // 또는 'color("255,192,203")'로 RGB 값 사용 가능
        // }); 컬러 바꾸기 처리


      const tilesetCenter = ts.boundingSphere.center;
      const tilesetCenterCartographic =
        Cesium.Cartographic.fromCartesian(tilesetCenter);
      const adjustedHeight = tilesetCenterCartographic.height + adjustHeight;
      // const adjustedHeight = tilesetCenterCartographic.height;
      const newCenter = Cesium.Cartesian3.fromRadians(
        tilesetCenterCartographic.longitude,
        tilesetCenterCartographic.latitude,
        adjustedHeight,
      );
      const translation = Cesium.Cartesian3.subtract(
        newCenter,
        tilesetCenter,
        new Cesium.Cartesian3(),
      );
      ts.modelMatrix = Cesium.Matrix4.fromTranslation(
        translation,
        ts.modelMatrix,
      );
      
      return { path, tileset: ts, splitDirection , name};
    };

    const results: Promise<{
      path: string;
      tileset: Cesium.Cesium3DTileset;
      splitDirection: Cesium.SplitDirection;
      name?: string; // 이름 추가
    }>[] = [];

    paths.forEach(
      ({
        path,
        adjustHeight = 0,
        splitDirection = Cesium.SplitDirection.NONE,
        name
      }) => {
        const tsWrapper = prevTilesets.get(path);
        if (!tsWrapper) {
          const result = foo(path, adjustHeight, splitDirection, name);
          results.push(result);
        } else {
          // eslint-disable-next-line object-shorthand
          results.push(Promise.resolve({ path, tileset: tsWrapper.tileset, splitDirection, name: tsWrapper.name }));
        }}
    );

    Promise.all(results).then((rs) => {
      const tsm = new Map<string, TilesetWithName>();
      rs.forEach((r) => {
        const { tileset, splitDirection, name } = r;
        tileset.splitDirection = splitDirection;
        tileset.show = true;
        tsm.set(r.path, { tileset, name }); // 이름과 함께 저장
      });
     
      set((state) => ({
        ...state,
        tilesetMap: tsm,
      }));

      return tsm; 
    });
  };

  return {
    viewer: null,
    phase: {
      position: [-3051522.38852034, 4040815.0796598564, 3865339.2794841235],
      orientation: {
        heading: 3.93971674518829,
        pitch: -0.23865777394028398,
        roll: 0.000008137311466427377,
      },
    },
    setPhase: (data) => {
      const { viewer } = get();

      if (viewer) {
        viewer.camera.flyTo({
          duration: 1,
          destination: new Cesium.Cartesian3(...data.position),
          orientation: data.orientation,
        });
      }
      set({ phase: data });
    },
    setViewer: (viewer) => {
      if (viewer) {
        viewer.camera.flyTo({
          duration: 1,
          destination: new Cesium.Cartesian3(...get().phase.position),
          orientation: get().phase.orientation,
        });
      }
      set({ viewer });
    },
    setTilsets,
    tilesetMap: new Map<string, TilesetWithName>(),
  };
});

export default useViewStore;
