import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import CesiumMapDev from "../../components/CesiumMap.dev";
import { spaceModeVm } from "../../data/models/space-mode.data";
import { SUNGSU_TILESET_ADJUST_HEIGHT } from "../../constants";
import useViewStore from "../../stores/viewer.store";

export default function SpaceModePage() {
  const { setPhase } = useViewStore();

  // useEffect(() => {
  //   setPhase(spaceModeVm.phase);
  // }, [setPhase]);

  const [selectedItem, setSelectedItem] = React.useState(spaceModeVm.items[0]);

  const { setTilsets } = useViewStore();

  useEffect(() => {
    if (selectedItem) {
      setTilsets([
        {
          path: selectedItem.modelPath,
          adjustHeight: SUNGSU_TILESET_ADJUST_HEIGHT,
        },
      ]);
      setPhase(selectedItem.phase);
    }
  }, [selectedItem, setPhase, setTilsets]);

  return (
    <Box
      sx={{
        width: "100dvw",
        height: "100dvh",
      }}
    >
      <CesiumMapDev />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#97c4eaa3",
        }}
      >
        {spaceModeVm.items.map((item) => {
          return (
            <Button
              key={item.name}
              onClick={() => {
                console.log("item", item);
                setSelectedItem(item);
              }}
            >
              {item.name}
            </Button>
          );
        })}

        {selectedItem && selectedItem.phases.length > 0 && (
          <Box>
            {selectedItem.phases.map((phase) => {
              return (
                <Button
                  key={phase.name}
                  onClick={() => {
                    setPhase(phase.phase);
                  }}
                >
                  {phase.name}
                </Button>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
}
