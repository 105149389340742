/* eslint-disable import/order */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DownArrow } from "../../../../icons";
import { TItem } from "../../../../data/models/space-mode.data";
import { modelings } from "../../../../data/modelings.data";
import useViewStore from "../../../../stores/viewer2.store";
import { SUNGSU_TILESET_ADJUST_HEIGHT } from "../../../../constants";

const SpaceMode = ({
  project,
  isOpen,
  onToggle,
}: {
  project: TItem;
  isOpen: boolean;
  onToggle: () => void;
}) => {
  const backgroundStyle = {
    backgroundColor: "#393939CC",
    borderRadius: "5px",
    marginBottom: "8px",
  };

  const { setTilsets } = useViewStore();
  const { setPhase } = useViewStore();
  const [selboxItem, setSelboxItem] = useState<string>('');

  const filteredModelings = modelings.filter((model) => {
    if (project.name === '성수클러스터_1차') {
      return model.name !== 'C06';
    }
    if (project.name === '성수클러스터_2차') {
      return true;
    }
    return project.name.includes(model.name);
  });


  useEffect(() => {
    if(isOpen){
      setPhase(project.phase);
      setTilsets([
        {
          path: project.modelPath,
          adjustHeight: project.name==="Bucheon Factory134 1차" ? -59 : SUNGSU_TILESET_ADJUST_HEIGHT,
        },
      ]);

      setSelboxItem(JSON.stringify(project.phase));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOpen])


  useEffect(() => {
    if (isOpen && filteredModelings.length > 0) {
      const firstModel = filteredModelings[0];
      setTilsets([
        {
          path: firstModel.modelPath,
          adjustHeight: SUNGSU_TILESET_ADJUST_HEIGHT,
        },
      ]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setPhase, setTilsets]);

  return (
    <Box sx={backgroundStyle}>
      <Button
        sx={{
          padding: "0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
          color: isOpen ? "yellow" : "white",
        }}
        onClick={onToggle}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Typography
            sx={{
              size: "14px",
              lineHeight: "22px",
              letterSpacing: "-0.43px",
              fontWeight: "700",
            }}
          >
            {project.name}
          </Typography>
        </Box>
        <Typography sx={{ fontSize: "10px" }}>
          {project.date}_{project.desc}
        </Typography>
        <DownArrow sx={{ marginLeft: "auto", width: "30px", height: "30px" }} />
      </Button>
      {isOpen && (
        <Box>
          <FormControl size="small" sx={{ m: 1, minWidth: 224 }}>
            <Select
              id="demo-select-small"
              sx={{
                fontSize: "10px",
                padding: "0px",
                color: "white",
                borderRadius: "5px",
                border: "solid 0.3px",
              }}
              value={selboxItem}
              onChange={(event)=>{
                setPhase(JSON.parse(event.target.value));
                  console.log(event.target.value);
                  setSelboxItem(event.target.value);

              }}
            >
              {/* {(project.name === "성수클러스터_1차" || project.name === '성수클러스터_2차') && ( */}
                <MenuItem key="0" value={JSON.stringify(project.phase)}>
               default view
                </MenuItem>
              {(project.name === "반포 주공아파트 1차" || project.name === "반포 주공아파트 2차") && (
                 <MenuItem key="0" value={JSON.stringify({
                    position: [-3048221.5446273605, 4046553.7318859147, 3862058.8829753385],
                    orientation: {
                      heading: 0.4212647710587376,
                      pitch: -0.45391547108268515,
                      roll: 0.000012188846101324202
                    }
                })}>
                      108-dong
                  </MenuItem>
               )} 


              {filteredModelings.map((model, index) => (
                <MenuItem key={index+1} value={JSON.stringify(model.phase)}>
                  {model.name}_{model.desc}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default SpaceMode;
