import React, { useEffect, useRef, } from "react";
import { Viewer as CesiumViewer } from "cesium";
import * as Cesium from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import useViewStore from "../stores/viewer.store";


Cesium.Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_TOKEN || "";

const CesiumMap = ({ children }: { children?: React.ReactNode }) => {
  const divRef = useRef<HTMLDivElement>(null);
  // const [viewer, setViewer] = useState<CesiumViewer | null>(null);
  const { viewer, setViewer } = useViewStore();
  // const { commonData } = useCommonDataStore();

  useEffect(() => {
    if (divRef.current && !viewer) {

      const v = new CesiumViewer(divRef.current, {
        animation: false,
        timeline: false,
        fullscreenButton: true,
        baseLayerPicker: true,
        geocoder: true,
        homeButton: false,
        // infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        navigationHelpButton: true,
        
      });

      setViewer(v);

      // const camera = viewer.camera;

      // // 카메라 각도 변경 이벤트 등록
      // camera.changed.addEventListener((p1,p2,p3) => {
      //     console.log(p1,p2,p3);
      // });
    }
  }, [setViewer,viewer]);

  // useEffect(() => {
  //   if (viewer) {
  //     viewer.camera.changed.addEventListener((p1, p2, p3) => {
  //       const { camera } = viewer;
  //       console.log(p1, p2, p3);
  //       console.log(camera.position);
  //       console.log(camera.heading);
  //       console.log(camera.pitch);
  //       console.log(camera.roll);
  //     });
  //   }
  // }, [viewer]);

  const { tilesetMap } = useViewStore();

  useEffect(() => {
    if (viewer && tilesetMap) {
      tilesetMap.forEach((ts) => {
        if (ts.tileset && !viewer.scene.primitives.contains(ts.tileset)) {
          viewer.scene.primitives.add(ts.tileset);
        }
      });

      const navigationHelpButton = viewer.navigationHelpButton.viewModel;
      navigationHelpButton.tooltip = '조작 도움말'; // 툴팁 변경

    }
  }, [viewer, tilesetMap]);

  // useEffect(() => {
  //   if (viewer) {
  //     Cesium.Cesium3DTileset.fromUrl(
  //       "/models/3d-tiles-merged/2023/Ak_valley/tileset.json",
  //     ).then((ts) => {
  //       const tilesetCenter = ts.boundingSphere.center;
  //       const tilesetCenterCartographic =
  //         Cesium.Cartographic.fromCartesian(tilesetCenter);
  //       const adjustedHeight =
  //         tilesetCenterCartographic.height + SUNGSU_TILESET_ADJUST_HEIGHT;
  //       const newCenter = Cesium.Cartesian3.fromRadians(
  //         tilesetCenterCartographic.longitude,
  //         tilesetCenterCartographic.latitude,
  //         adjustedHeight,
  //       );
  //       const translation = Cesium.Cartesian3.subtract(
  //         newCenter,
  //         tilesetCenter,
  //         new Cesium.Cartesian3(),
  //       );
  //       // eslint-disable-next-line no-param-reassign
  //       ts.modelMatrix = Cesium.Matrix4.fromTranslation(
  //         translation,
  //         ts.modelMatrix,
  //       );
  //       viewer.scene.primitives.add(ts);

  //       // ts.tileVisible.addEventListener((tile) => {
  //       //   console.log('타일이 보입니다:', tile);
  //       // });

  //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //       viewer.screenSpaceEventHandler.setInputAction((movement: any) => {
  //         const pickedObject = viewer.scene.pick(movement.position);
  //         if (
  //           Cesium.defined(pickedObject) &&
  //           pickedObject.primitive instanceof Cesium.Cesium3DTileset
  //         ) {
  //           console.log("3D 타일셋을 클릭했습니다:", pickedObject);
  //         }
  //       }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  //     });
  //   }
  // }, [viewer]);

  useEffect(() => {
    if (viewer) {
      const position = new Cesium.Cartesian3(
        -3076123.787318728,
        4078796.354334572,
        3902956.2471823636,
      );
      viewer.camera.flyTo({
        // destination: Cesium.Cartesian3.fromDegrees(127.053184783272911, 37.543457062986135, 1000),
        duration: 1,
        destination: position,
        orientation: {
          heading: 6.219275859871619,
          pitch: -1.5706878936188335,
          roll: 0.0,
        },
      });
    }
  }, [viewer]);

  return (
    <div
      ref={divRef}
      style={{ width: "100dvw", height: "100dvh", backgroundColor: "green" }}
    >

{children && children}
    </div>
  );
};

export default CesiumMap;
