import { TPhase } from "../..";

export type TItem = {
  name: string;
  date: string;
  phase: TPhase;
  modelBeforePath: string;
  modelAfterPath: string;
};

export type TTimelineModeVm = {
  phase: TPhase;
  items: TItem[];
};

const dataBaseUrl = process.env.REACT_APP_DATA_BASE_URL || "";

export const timelineModeVm: TTimelineModeVm = {
  phase: {
    position: [-3051457.7220468684, 4042704.247646128, 3864409.8293511905],
    orientation: {
      heading: 0.313156875998288,
      pitch:-0.27180005163537535,
      roll: 0.000030882813662813646,
    },
  },
  items: [
    {
      name: "반포주공아파트 철거",
      date: "2022",
      phase: {
        position: [ -3048446.537082407, 4048246.620852872, 3861223.9001776567],
        orientation: {
          heading: 0.4212647721021163,
          pitch: -0.45391547585525416,
          roll: 0.000012186466604724444
        },
      },
      modelBeforePath: `${dataBaseUrl}/models/Banpo/2022/tileset.json`,
      modelAfterPath: `${dataBaseUrl}/models/Banpo/2024/tileset.json`,
    },
    {
      name: "재건축 완료",
      date: "2030",
      phase: {
        position: [ -3048446.537082407, 4048246.620852872, 3861223.9001776567],
        orientation: {
          heading: 0.4212647721021163,
          pitch: -0.45391547585525416,
          roll: 0.000012186466604724444
        },
      },
      modelBeforePath: `${dataBaseUrl}/models/Banpo/2024/tileset.json`,
      modelAfterPath: `${dataBaseUrl}/models/Bucheon_factory134/tileset.json`,
    },


    {
      name: "22철거",
      date: "2022",
      phase: {
        position: [ -3036530.2092677755, 4057822.181973074, 3859743.3944031326],
        orientation: {
          heading: 5.054623042973082,
          pitch: -0.32777784687562717,
          roll: 6.283180920221103
        },
      },
      modelBeforePath:`${dataBaseUrl}/models/Bucheon_factory134/tileset.json`,
      modelAfterPath: `${dataBaseUrl}/models/3d-tiles-merged/2024/merged-0/K-HQ/tileset.json`,
    },

    {
      name: "철거",
      date: "2023",
      phase: {
        position: [
          -3022257.148672632, 4043950.9089256343, 3884930.5842272164],
        orientation: {
          heading: 6.2001567464042235,
          pitch: -0.2298476001155474,
          roll: 0.005178302731736403
        },
      },
      modelBeforePath:`${dataBaseUrl}/models/Paju_Guemchon/tileset.json`,
      modelAfterPath: `${dataBaseUrl}/models/3d-tiles-merged/2024/merged-0/K-HQ/tileset.json`,
    },
    {
      name: "남서울 아파트",
      date: "2022",
      phase: {
        position: [ -3048446.537082407, 4048246.620852872, 3861223.9001776567],
        orientation: {
          heading: 0.4212647721021163,
          pitch: -0.45391547585525416,
          roll: 0.000012186466604724444
        },
      },
      modelBeforePath:`${dataBaseUrl}/models/Banpo/1979/tileset.json`,
      modelAfterPath: `${dataBaseUrl}/models/Banpo/2022/tileset.json`,
    },
    {
      name: "재건축 완료",
      date: "2030",
      phase: {
        position: [ -3048446.537082407, 4048246.620852872, 3861223.9001776567],
        orientation: {
          heading: 0.4212647721021163,
          pitch: -0.45391547585525416,
          roll: 0.000012186466604724444
        },
      },
      modelBeforePath:`${dataBaseUrl}/models/Banpo/2024/tileset.json`,
      modelAfterPath: `${dataBaseUrl}/models/Bucheon_factory134/tileset.json`,
    },
  ],
};

export default { timelineModeVm };
