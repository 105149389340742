import React from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import Timeline from "../components/expo/Timeline";
import CesiumMap from "../components/expo/CesiumMap";
import Drawer from "../components/expo/Drawer";
import useCommonDataStore from "../stores/common2.store";
import SpaceModePopup from "../components/Drawer/sideMenu/SpaceModePopup";


const Slider = styled.div`
  position: absolute;
  left: 50%;
  top: 0px;
  background-color: #d3d3d3;
  width: 5px;
  height: 100%;
  z-index: 10;
  &:hover {
    cursor: ew-resize;
  }
`;

export default function ExpoPage() {
  const { commonData } = useCommonDataStore();
  const sliderRef = React.useRef(null);


  return (
    <Box
      sx={{
        width: "100dvw",
        height: "100dvh",
      }}
    >
      <Timeline sliderRef={sliderRef} />
      <CesiumMap>
         
          <Slider
            ref={sliderRef}
            style={{
              visibility: commonData.slider ? "visible" : "hidden",
            }}
          />
        
      </CesiumMap>
      <Drawer />
      <SpaceModePopup />
    </Box>
  );
}
