import React, { useEffect, useRef } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import EmptyLayout from "./layouts/EmptyLayout";
import NotFoundPage from "./pages/NotFoundPage.tsx";
import MainPage from "./pages/MainPage";
import MaterplanPage from "./pages/dev/MaterplanPage";
import SpaceModePage from "./pages/dev/SpaceModePage";
import TimelineModePage from "./pages/dev/TimelineModePage";
import StudiosPage from "./pages/dev/StudiosPage";
import ExpoPage from "./pages/\bexpoPage";

export default function App() {

  const location = useLocation();
  const prevLocation = useRef(location.pathname);

  useEffect(() => {
    // 이전 URL과 현재 URL을 비교
    if (prevLocation.current !== location.pathname) {
      window.location.reload();
      prevLocation.current = location.pathname; // 현재 URL을 이전 URL로 업데이트
    }
  }, [location]);

  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Routes>
        {/* 프러덕션 라우트 */}
        <Route element={<EmptyLayout />} path="/">
          <Route index element={<Navigate replace to="/expo" />} />
          <Route index element={<MainPage />} path="Kproject" />
          <Route index element={<ExpoPage />} path="expo" />
        </Route>
        {/* 개발용 라우트 */}
        <Route element={<EmptyLayout />} path="/dev">
          <Route element={<StudiosPage />} path="studios" /> 
          <Route element={<MaterplanPage />} path="masterplan" />
          <Route element={<SpaceModePage />} path="space-mode" />
          <Route element={<TimelineModePage />} path="timeline-mode" />
        </Route>
        {/* Notfound page */}
        <Route element={<EmptyLayout />} path="/">
          <Route element={<NotFoundPage />} path="*" />
        </Route>
      </Routes>
    </div>
  );
}
