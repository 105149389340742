import React from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import * as Cesium from "cesium";
import useCommonDataStore from "../../../stores/common2.store";
import useViewStore from "../../../stores/viewer2.store";
import { landingVm } from "../../../data/expo/models/landing.data";
import Detail from "./detail";
import { SUNGSU_TILESET_ADJUST_HEIGHT } from "../../../constants";


const buttonStyle = {
  color: "#FFFFFF",
  borderColor: "#FFFFFF",
  borderRadius: "5px",
  width: "100%",
  justifyContent: "flex-start",
  fontFamily: "Arimo",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "22px",
  alignItems: "center",
  letterSpacing: "-0.43px",
  boxSizing: "border-box",
  border: "1px solid #FFFFFF",
  height: "24px",
  paddingX: "12px",
};

const buttonStyle2 = {
    color: "#FFFFFF",
    borderColor: "#FFFFFF",
    borderRadius: "5px",
    Height: "30px",
    width: "100%",
    justifyContent: "flex-start",
    fontFamily: "Arimo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
    alignItems: "center",
    letterSpacing: "-0.43px",
    boxSizing: "border-box",
    border: "1px solid #FFFFFF",
    height: "24px",
    paddingX: "12px",
    "&:hover": {
                  color: "yellow",
                },
  };

const hamburgerIconStyle = {
  width: "24px",
  height: "24px",
  display: "flex",
  marginTop: "15px",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "rgba(0, 0, 0, 0.75)",
  padding: "7px 19px 30px 19px",
  marginLeft: "5px",
  borderRadius: "50%",
};

const titleStyle = {
  width: "100%",
  marginTop: "24px",
  fontFamily: "Arimo",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "30px",
  lineHeight: "22px",
  letterSpacing: "-0.43px",
  color: "#747474",
  cursor: "pointer",
};

export default function Drawer() {
  // const [folder, setFolder] = useState<boolean>(false);
  const { commonData, setCommonData } = useCommonDataStore();
  const { viewer } = useViewStore();

  const { setPhase } = useViewStore();
  const { setTilsets } = useViewStore();

  const setFolder=(tf:boolean)=>{
    setCommonData({ ...commonData, folder: tf});
  }

  const resetProject = (project: string) => {
    
    if(project === 'Banpo jugong Apt.'){ 
      setPhase({
        position: [-3048446.537082407, 4048246.620852872, 3861223.9001776567],
        orientation: {
          heading: 0.4212647721021163,
          pitch: -0.45391547585525416,
          roll: 0.000012186466604724444
        },
      });
    }else if(project === 'Bucheon Factory134'){
      setPhase({
        position: [ 
          -3036530.2092677755, 4057822.181973074, 3859743.3944031326],
        orientation: {
          heading: 5.054623042973082,
          pitch: -0.32777784687562717,
          roll: 6.283180920221103
        },
      });
    }else if(project === 'Paju Saemal Village'){
      setPhase({
        position: [
          -3022257.148672632, 4043913.9089256343, 3884950.5842272164],
        orientation: {
          heading: 6.2001567464042235,
          pitch: -0.2298476001155474,
          roll: 0.000001778302731736403
        },
      })

    }

    if( project ==="Bucheon Factory134"){
      setTilsets([
        {
          path: landingVm.items[1].modelPath,
          adjustHeight:  -59,
        },
      ]);
    }else if(project ==="Banpo jugong Apt."){
      setTilsets([
        {
          path: landingVm.items[0].modelPath,
          adjustHeight: SUNGSU_TILESET_ADJUST_HEIGHT,
        },
      ]);
    }
    else if(project ==="Paju Saemal Village"){
      setTilsets([
        {
          path: landingVm.items[2].modelPath,
          adjustHeight:  SUNGSU_TILESET_ADJUST_HEIGHT,
        },
      ]);
    }


    setCommonData({ 
        project,
        isActive: "",
        folder:false,
        slider:false,
        openIndex: null,
  });

}


  const resetMap=()=>{
    if (viewer) {
      const position = new Cesium.Cartesian3(
       ...landingVm.phase.position
      );
      viewer.camera.flyTo({
        duration: 1,
        destination: position,
        orientation: landingVm.phase.orientation,
      });

      setCommonData({ ...commonData, isActive: "", project: "" });     

      // setTilsets([
      //   {
      //     path: landingVm.items[0].modelPath,
      //     adjustHeight: SUNGSU_TILESET_ADJUST_HEIGHT,
      //   },
      // ]);
    }
  }



  return (
    <div style={{ position: "absolute", top: 0, left: 0 }}>
      {!commonData.folder ? null : (
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
        >
          <ButtonBase
            sx={hamburgerIconStyle}
            onClick={() => {
              setFolder(false);
            }}
          >
            <MenuIcon sx={{ color: "white" }} />
          </ButtonBase>
        </Box>
      )}
      <Box
        sx={{
          color: "white",
          width: 240,
          height: "100dvh",
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          flexDirection: "column",
          alignItems: "center",
          paddingX: "12px",
          minHeight: "500px",
          overflowY: "auto",
          msOverflowStyle: "none", // 스크롤 숨김
          scrollbarWidth: "none", // 스크롤 숨김
          display: commonData.folder ? "none" : "flex"
        }}
      >
        {/* ------- 헤더 Start ------- */}
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
        >
          <ButtonBase
            sx={{
              width: "24px",
              height: "24px",
              display: "flex",
              paddingTop: "22px",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setFolder(true);
            }}
          >
            <MenuIcon sx={{ color: "white" }} />
          </ButtonBase>
          <Typography key={"typo"} sx={titleStyle} 
          onClick={()=>{resetProject(''); resetMap();}}>
            &nbsp;Spatial Log
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "1px",
            backgroundColor: "#747474",
            marginY: "16px",
          }}
        />
        {/* ------- 헤더 End ------- */}

        {/* ------- 메뉴 Start ------- */}



        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
            width: "100%",
          }}
        >
        <Typography
            key={"Archives"}
            sx={{
              fontFamily: "Arimo",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "22px",
              letterSpacing: "-0.43px",
              color: "#FFFFFF",
            }}
          >
            {commonData.project === '' ? 'Archives' : commonData.project}
        </Typography>
        </Box>

        <Box
        sx={{

          minHeight:'10px',
          height:'10%',
          width:'100%'
        }}
        ></Box>
            {commonData.project === '' ? 
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
           
            rowGap: "16px",
            width: "100%",
          }}
        >
            <>
              <Typography
                key={"Projects"}
                sx={{
                  // marginTop: "24px",
                  fontFamily: "Arimo",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "22px",
                  letterSpacing: "-0.43px",
                  color: "#FFFFFF",
                }}
              >
               Projects
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "white",
                }}
              />
            </>
          
            <ButtonBase sx={{ ...buttonStyle2}} onClick={()=>{resetProject("Banpo jugong Apt.")}}>Banpo jugong Apt.</ButtonBase>
            <ButtonBase sx={{ ...buttonStyle2}} onClick={()=>{resetProject("Bucheon Factory134")}}>Bucheon Factory134</ButtonBase>
            <ButtonBase sx={{ ...buttonStyle2 }} onClick={()=>{resetProject("Paju Saemal Village")}}>Paju Saemal Village</ButtonBase>
        </Box> 
        : <Detail/>}
       
       {/* ------- 메뉴 End ------- */}

        <Box sx={{ flexGrow: 1 }} />
        <ButtonBase sx={{ ...buttonStyle, marginBottom: "10px" }}>
          Admin page
        </ButtonBase>
      </Box>
    </div>
  );
}
