import { create } from "zustand";

type TCommon2Data = {
    project: string; // banpo, bucheon, paju
    isActive: string; // krafton, masterPlan, Space mode, TimeLine mode
    folder : boolean;
    slider : boolean;
    openIndex: string | null;
};

const useCommonDataStore = create<{
  commonData: TCommon2Data;
  setCommonData: (data: TCommon2Data) => void;
}>((set) => ({
  commonData: {
    project: "",
    isActive: "",
    folder:false,
    slider:false,
    openIndex: null,
  },
  setCommonData: (data) => set({ commonData: data }),
}));

export default useCommonDataStore;
