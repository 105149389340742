
import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import useCommonDataStore from "../../../stores/common2.store";
import FolderMenu from "./sideMenu/FolderMenu";
import SpaceMode from "./sideMenu/SpaceMode";
import { spaceModeVm } from "../../../data/expo/models/space-mode.data";
// import { timelineModeVm } from "../../../data/models/timeline-mode.data";

export default function Detail() {
    const { commonData , setCommonData } = useCommonDataStore();

    const resetMenu=()=>{
        setCommonData({ ...commonData, isActive: ''});
      }

      // eslint-disable-next-line spaced-comment
      //스페이스모드 메뉴 선택
      const setOpenIndex = (idx: string | null) => { 
        setCommonData({ ...commonData, openIndex: idx});
      }
    
      const handleToggle = (index: string) => {
        setOpenIndex((commonData.openIndex === index ? null : index));
      };


  useEffect(() => {
    if(commonData.isActive !== "Space mode" && commonData.openIndex){
      setOpenIndex(null);
    }
  },[commonData.isActive, commonData.openIndex])


    return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
           
            rowGap: "16px",
            width: "100%",
          }}
        >
          {commonData.isActive !== "Timeline mode" && (
            <>
              <Typography
                key={"Archives"}
                sx={{
                  // marginTop: "24px",
                  fontFamily: "Arimo",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "22px",
                  letterSpacing: "-0.43px",
                  color: "#FFFFFF",
                }}
                onClick={()=>{resetMenu();}}
                
              >
                {commonData.isActive === "" ? "Mode" : commonData.isActive}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "white",
                }}
              />
            </>
          )}
          <FolderMenu
            msg="공간정보를 공간의 이동에 따라 보는 모드"
            // phase={spaceModeVm.phase}
            title="Space mode"
          />
          <div
            style={{ width: "100%",
                    display: commonData.isActive !== "Space mode" ? "none" : "block" }}
          >
            {commonData.project==="Banpo jugong Apt." ? spaceModeVm.items.slice(0, 2).map((project) => (
              <SpaceMode
                isOpen={commonData.openIndex === project.name}
                key={`${project.name}index`}
                project={project}
                onToggle={() => handleToggle(project.name)}
              />
            )):null}

            {commonData.project==="Bucheon Factory134" ? spaceModeVm.items.slice(2, 3).map((project) => (
              <SpaceMode
                isOpen={commonData.openIndex === project.name}
                key={`${project.name}index`}
                project={project}
                onToggle={() => handleToggle(project.name)}
              />
            )):null}

            {commonData.project==="Paju Saemal Village" ? spaceModeVm.items.slice(3, 4).map((project) => (
              <SpaceMode
                isOpen={commonData.openIndex === project.name}
                key={`${project.name}index`}
                project={project}
                onToggle={() => handleToggle(project.name)}
              />
            )):null}
          </div>
        <FolderMenu
            msg="공간정보를 시간의 흐름에 따라 보는 모드"
            // phase={timelineModeVm.phase}
            title="Timeline mode"

        />
        </Box>
    );
}