import React, { useEffect } from "react";
import { Box } from "@mui/material";
import * as Cesium from "cesium";
import CesiumMapDev from "../../components/CesiumMap.dev";
import { kraptonVm } from "../../data/k-projects/krafton.data";
// import { SUNGSU_TILESET_ADJUST_HEIGHT } from "../../constants";
import useViewStore from "../../stores/viewer.store";
import { TPin } from "../../data";

const svgContent = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_333_334)">
<path d="M26.25 12.5C26.25 21.25 15 28.75 15 28.75C15 28.75 3.75 21.25 3.75 12.5C3.75 9.51631 4.93526 6.65483 7.04505 4.54505C9.15483 2.43526 12.0163 1.25 15 1.25C17.9837 1.25 20.8452 2.43526 22.955 4.54505C25.0647 6.65483 26.25 9.51631 26.25 12.5Z" stroke="#F3F3F3" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 16.25C17.0711 16.25 18.75 14.5711 18.75 12.5C18.75 10.4289 17.0711 8.75 15 8.75C12.9289 8.75 11.25 10.4289 11.25 12.5C11.25 14.5711 12.9289 16.25 15 16.25Z" stroke="#F3F3F3" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_333_334">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>
`;

const svgUrl = `data:image/svg+xml;base64,${btoa(svgContent)}`;

export default function StudiosPage() {
  const { viewer, setPhase } = useViewStore();

  useEffect(() => {
    setPhase(kraptonVm.phase);
  }, [setPhase]);

  useEffect(() => {
    if (viewer) {
      kraptonVm.pins.forEach((p) => {
        viewer.entities.add({
          name: p.name,
          position: Cesium.Cartesian3.fromDegrees(...p.position),
          billboard: {
            image: svgUrl,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          },
          properties: p,
        });
      });
    }
  }, [viewer]);

  const [pinClickHandler, setClickHandler] =
    React.useState<Cesium.ScreenSpaceEventHandler | null>(null);

  useEffect(() => {
    if (viewer)
      setClickHandler(new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas));
  }, [viewer]);

  useEffect(() => {
    if (viewer) {
      viewer.camera.moveEnd.addEventListener((p1, p2, p3) => {
        // const { camera } = viewer;
        console.log(p1, p2, p3);
        const zoom = viewer.camera.positionCartographic.height;
        console.log("Zoom level:", zoom);
      });
    }
  }, [viewer]);

  useEffect(() => {
    if (pinClickHandler && viewer) {
      pinClickHandler.setInputAction(
        (click: { position: Cesium.Cartesian2 }) => {
          const pickedObject = viewer.scene.pick(click.position);
          // console.log(pickedObject.primitive);

          if (pickedObject) {
            const { properties } = pickedObject.id;
            console.log(properties);
            if (properties) {
              const p = properties.getValue("name") as TPin;
              viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(
                  ...p.position,
                  p.zoom,
                ), // 줌 인할 위치와 높이 설정
                orientation: {
                  heading: Cesium.Math.toRadians(0.0),
                  pitch: Cesium.Math.toRadians(-90.0),
                  roll: 0.0,
                },
              });
            }
          }
        },
        Cesium.ScreenSpaceEventType.LEFT_CLICK,
      );
    }
  }, [pinClickHandler, viewer]);

  return (
    <Box
      sx={{
        width: "100dvw",
        height: "100dvh",
      }}
    >
      <CesiumMapDev />
      {/* <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#97c4eaa3",
        }}
      >
        {masterplanVm.items.map((item) => {
          return (
            <Button
              key={item.name}
              onClick={() => {
                setPhase(item.phase);
              }}
            >
              {item.name}
            </Button>
          );
        })}
      </Box> */}
    </Box>
  );
}
